.global_loadspinner {
	border: 8px solid var(--theme_foreground_light);
	border-top: 8px solid var(--theme_background_main);
	border-radius: 50%;
	animation: rotate 1.5s linear infinite;
}

.global_scrollable {
    overflow: scroll;
}

.global_scrollable::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

.global_scrollable::-webkit-scrollbar-thumb {
	background-color: #FFFFFF;
	border-radius: 3px;
}

.global_scrollable::-webkit-scrollbar-corner {
	background-color: transparent;
}

.global_overflow_parent {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
}

.global_overflow_children {
    grid-column: 1;
    grid-row: 1;
}

@keyframes rotate {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}