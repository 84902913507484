* {
    /* default styling for all components */
    padding: 0px;
    margin: 0px;
    width: 100%;
    height: 100%;
    font-family: "Roboto", sans-serif;
    box-sizing: border-box;
    overflow: hidden;
    user-select: none;
}

#page_root {
    /* page layout (full) */
    display: grid;
    grid-template-columns: 50px auto min(300px, calc(100% / 3));
    grid-template-rows: 30px auto min(150px, calc(100% / 5));
}

@media screen and (max-width: 600px) {
    #page_root {
        grid-template-columns: 0px auto min(300px, calc(100% / 3));
        grid-template-rows: 30px auto min(150px, calc(100% / 5));
    }
}

@media screen and (max-width: 400px) {
    #page_root {
        grid-template-columns: 0px auto 0px;
        grid-template-rows: 30px auto min(150px, calc(100% / 5));
    }
}

.page_root_plain {
    /* page layout (editor body only) */
    grid-column: 1 / 4;
    grid-row: 1 / 4;
    display: grid;
    grid-template-columns: 0px 100% 0px;
    grid-template-rows: 0px auto 0px;
}

.page_header {
    /* page header location in grid */
    grid-column: 1/4;
    grid-row: 1;
}

.page_sidebar_left {
    /* page left sidebar location in grid */
    grid-column: 1;
    grid-row: 2/4;
}

.page_sidebar_right {
    /* page right sidebar location in grid */
    grid-column: 3;
    grid-row: 2;
}

.page_body {
    /* page body location in grid */
    grid-column: 2;
    grid-row: 2;
}

.page_footer {
    /* page footer location in grid */
    grid-column: 2/4;
    grid-row: 3;
}

.popup_template {
    /* loading popup location in grid */
    grid-column: 1/4;
    grid-row: 2/4;
}