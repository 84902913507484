.page_sidebar_left {
    background-color: var(--theme_background_dark);
}

.page_sidebar_left_item {
    height: 60px;
    color: var(--theme_foreground_dark);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
}

.page_sidebar_left_item:hover {
    color: var(--theme_foreground_light);
    background-color: rgba(255, 255, 255, 0.3);
}

.page_sidebar_left_item > i {
    height: fit-content;
    text-align: center;
    margin-bottom: 5px;
}

.page_sidebar_left_item > p {
    height: fit-content;
    font-size: 10px;
    line-height: 10px;
    text-align: center;
}