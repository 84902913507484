.popup_template {
    background-color: rgba(255, 255, 255, 0.3);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2; /* cover over textarea */
}

.popup_template::before {
    content: "";
    width: 80%;
    max-width: calc(30px * 16);
    height: 10px;
    background-color: var(--theme_background_main);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.popup_template_window {
    /* popup window with classic 16:9 ratio */
    width: 80%;
    max-width: calc(30px * 16);
    height: calc(30px * 9);
    background-color: var(--theme_background_dark);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.popup_template_window > div {
    /* popup content alignments */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.popup_template_window > div > * {
    width: fit-content;
    height: fit-content;
    color: var(--theme_foreground_light);
    text-align: center;
}

.popup_global_title > p {
    font-size: 32px;
    font-family: "Audiowide", cursive;
    padding: 0px 20px;
}

.popup_global_subtitle > p {
    font-size: 16px;
    font-family: "Audiowide", cursive;
    padding: 0px 20px;
}

.popup_loading > .popup_global_subtitle {
    margin-bottom: 32px;
}

.popup_loading > .global_loadspinner {
    width: 100px;
    height: 100px;
}

.popup_warning > i {
    font-size: 50px;
    margin-bottom: 16px;
}