.page_body {
    background-color: var(--theme_background_dark);
}

.page_body_index {
    background-color: var(--theme_background_light);
    padding-top: 2px;
    padding-bottom: 10px;
}

.page_body_index > pre {
    height: 16px;
    text-align: center;
    font-size: 14px;
    color: var(--theme_foreground_dark);
}

.page_body_content {
    padding: 2px;
    background-color: var(--theme_background_light);
}

.page_body_content_editor {
    z-index: 1;
    color: transparent;
    background-color: transparent;
    caret-color: #FFFFFF;
    white-space: nowrap;
    border: none;
    outline: none;
    resize: none;
    overflow: scroll;
    user-select: auto;
}

.page_body_content_mirror {
    /* max-width is necessary for capture screenshot */
    min-width: 100%;
    max-width: max-content;
    position: relative;
    padding-right: 8px;
    padding-bottom: 8px;
}

.page_body_content_mirror_line > pre, .page_body_content_editor, .page_body_content_autocomplete_suggestion_content > pre {
    font-size: 14px;
	font-family: var(--theme_foreground_font);
	line-height: 16px;
    tab-size: 4;
}

.page_body_content_mirror_line {
    width: max-content;
    height: 16px;
    display: flex;
}

.page_body_content_mirror_line > pre {
    width: auto;
    height: 16px;
    padding-top: 0px;
}

.page_body_content_mirror_focus {
    color: white;
    background-color: var(--focus_color);
    border-radius: 2px;
}

.page_body_content_autocomplete {
    width: max-content;
    min-width: 250px;
    height: max-content;
    min-height: 100px;
    position: absolute;
    display: flex;
    flex-direction: column;
    border: 2px solid var(--theme_background_main);
    border-radius: 3px;
    background-color: var(--theme_background_dark);
    z-index: 2;
}

.page_body_content_autocomplete::before {
    content: "";
    width: 100%;
    height: 8px;
    background-color: var(--theme_background_main);
}

.page_body_content_autocomplete_suggestion {
    width: 100%;
    height: 16px;
    display: flex;
    color: var(--theme_foreground_light);
}

.page_body_content_autocomplete_suggestion:hover {
    background-color: var(--theme_background_light);
}

.page_body_content_autocomplete_suggestion_icon {
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 3px;
}

.page_body_content_autocomplete_suggestion_icon > i {
    width: fit-content;
    height: fit-content;
    font-size: 12px;
}

.page_body_content_autocomplete_suggestion_content {
    width: 100%;
    height: 16px;
    display: flex;
}

.page_body_content_autocomplete_suggestion_content > pre:nth-child(1) {
    width: 120px;
}

.page_body_content_autocomplete_suggestion_content > pre:nth-child(2) {
    width: max-content;
    color: var(--theme_foreground_warning);
}

.page_body_content_autocomplete_suggestion_content > pre:nth-child(3) {
    width: max-content;
}

/* grid layout */
.page_body {
    display: grid;
    grid-template-columns: 30px auto;
    grid-template-rows: 100%;
    grid-gap: 3px;
}

.page_body_index {
    grid-column: 1;
    grid-row: 1;
}

.page_body_content {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
    grid-column: 2;
    grid-row: 1;
}

.page_body_content_editor, .page_body_content_mirror {
    grid-column: 1/2;
    grid-row: 1/2;
}